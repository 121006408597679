/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 18, 2020 */



@font-face {
    font-family: 'open_sanslight';
    src: url('opensans-light-webfont.woff2') format('woff2'),
         url('opensans-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'bebas_neueregular';
    src: url('bebasneue-webfont.woff2') format('woff2'),
         url('bebasneue-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'bilboregular';
    src: url('bilbo-regular-webfont.woff2') format('woff2'),
         url('bilbo-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'open_sansregular';
    src: url('opensans-regular-webfont.woff2') format('woff2'),
         url('opensans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'thermalregular';
    src: url('merchantcopydoublesize-je7r-webfont.woff2') format('woff2'),
         url('merchantcopydoublesize-je7r-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}